@import '../../../styles/main';

.container {
  display: flex;
  justify-content: space-between;
  padding: 2px 24px;
  margin-top: 16px;

  @include mq(tablet-s) {
    padding: 16px 60px;
    margin-top: 20px;
  }

  .text {
    align-self: center;
  }
}

.toNotificationButton {
  display: flex;
  flex-flow: column;

  .icon {
    width: auto;
    height: 32px;

    @include mq(tablet-s) {
      height: 36px;
    }

    @include mq(tablet-m) {
      height: 40px;
    }

    @include mq(tablet-l) {
      height: 44px;
    }
  }

  .iconWrapper {
    position: relative;
    width: 32px;
    height: 32px;
    margin: 0 auto;

    @include mq(tablet-s) {
      height: 36px;
    }

    @include mq(tablet-m) {
      height: 40px;
    }

    @include mq(tablet-l) {
      height: 44px;
    }
  }

  .notificationBadge {
    position: absolute;
    top: -4px;
    right: -4px;
    display: flex;
    width: 16px;
    height: 12px;
    align-items: center;
    justify-content: center;
    background-color: $error-color;
    border-radius: 20px;
    color: $white-color;
    font-size: 8px;
    font-weight: 600;

    @include mq(tablet-s) {
      top: -8px;
      right: -8px;
      width: 20px;
      height: 16px;
      font-size: 8px;
    }

    @include mq(tablet-m) {
      top: -12px;
      right: -12px;
      width: 24px;
      height: 20px;
      font-size: 10px;
    }

    @include mq(tablet-l) {
      top: -16px;
      right: -16px;
      width: 28px;
      height: 24px;
      font-size: 12px;
    }
  }

  .buttonTitle {
    margin-top: 4px;
    font-size: 9px;
    font-weight: 300;

    @include mq(tablet-s) {
      margin-top: 8px;
      font-size: 12px;
      font-weight: 400;
    }

    @include mq(tablet-m) {
      margin-top: 12px;
      font-size: 15px;
      font-weight: 500;
    }

    @include mq(tablet-l) {
      margin-top: 14px;
      font-size: 18px;
    }
  }
}

.buttonWrapper {
  .backHistoryButton {
    width: 120px;
    height: 32px;
    padding: 4px 12px;
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;

    @include mq(tablet-s) {
      width: 142px;
      height: 40px;
      padding: 4px 12px;
      font-size: 14px;
    }

    @include mq(tablet-m) {
      width: 160px;
      height: 48px;
      padding: 4px 12px;
      font-size: 16px;
    }
  }
}
