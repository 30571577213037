@import '../../../styles/main';

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & .label {
    //
  }

  & .value {
    font-size: $font-size-little-big;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    text-align: right;

    @include mq(tablet-s) {
      font-size: calc(#{$font-size-little-big} * 1.5);
      line-height: 66px;
    }
  }
}
