@import 'src/styles/main';

$horizontal-margin: 24px;
$horizontal-margin-tablet-s: 60px;

.container {
  display: flex;
  width: 100vw;
  min-height: 100%;
  flex-direction: column;
  justify-content: space-between;

  & .wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-around;

    & .title {
      margin-left: $horizontal-margin;

      @include mq(tablet-s) {
        margin-left: $horizontal-margin-tablet-s;
      }
    }

    & .content {
      font-size: 16px;

      @include mq(tablet-s) {
        font-size: 30px;
      }
      & .contentValue {
        margin: 30px $horizontal-margin 0;
        overflow-wrap: break-word;

        @include mq(tablet-s) {
          margin: 0 $horizontal-margin-tablet-s;
        }
      }
    }

    & .buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 0 $horizontal-margin;

      @include mq(tablet-s) {
        margin: 0 $horizontal-margin-tablet-s;
      }
    }
  }
}
