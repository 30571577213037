@import 'src/styles/main';

$horizontal-margin: 24px;
$horizontal-margin-tablet-s: 60px;

.container {
  display: flex;
  width: 100vw;
  min-height: 100%;
  flex-direction: column;
  justify-content: space-between;

  & .wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-around;

    & .title {
      margin-left: $horizontal-margin;
      font-size: $font-size-huge;
      font-weight: bold;

      @include mq(tablet-s) {
        margin-left: $horizontal-margin-tablet-s;
        font-size: calc(#{$font-size-huge} * 1.6);
      }

      @include mq(tablet-m) {
        font-size: calc(#{$font-size-huge} * 1.8);
      }
    }

    & .labelValues {
      & .labelValue {
        padding: 20px;
        border: 1px solid $light-gray-color;
        margin: 6px $horizontal-margin;
        background-color: $light-gray-color;
        border-radius: 10px;

        @include mq(tablet-s) {
          margin: 12px $horizontal-margin-tablet-s;
        }
      }
    }

    & .buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 0 $horizontal-margin;

      @include mq(tablet-s) {
        margin: 0 $horizontal-margin-tablet-s;
      }
    }
  }
}
