@import 'src/styles/main';

.root {
  display: flex;
  align-items: center;

  & .container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: 84px 32px 0;

    @include mq(tablet-s) {
      margin: 0 80px;
    }

    & .title {
      font-size: 20px;
      font-style: normal;
      font-weight: bold;
      line-height: 140%;
      white-space: pre-wrap;

      @include mq(tablet-s) {
        font-size: 28px;
      }

      @include mq(tablet-m) {
        font-size: 32px;
      }
    }

    & .buttonWrapper {
      display: flex;
      justify-content: center;

      & .button {
        width: 192px;
      }
    }

    & .content {
      font-size: 16px;
      white-space: pre-wrap;

      @include mq(tablet-s) {
        font-size: 32px;
      }
    }
  }
}
