@import 'src/styles/main';

.root {
  display: flex;
  width: 32px;
  height: 16px;
  align-items: center;
  justify-content: center;
  background-color: $error-color;
  border-radius: 8px;

  @include mq(tablet-s) {
    width: 36px;
    height: 20px;
    border-radius: 12px;
  }

  @include mq(tablet-m) {
    width: 40px;
    height: 24px;
  }

  @include mq(tablet-l) {
    width: 48px;
    height: 28px;
    border-radius: 16px;
  }

  .title {
    color: $white-color;
    font-size: 10px;
    font-weight: 600;

    @include mq(tablet-s) {
      font-size: 12px;
    }

    @include mq(tablet-m) {
      font-size: 14px;
    }

    @include mq(tablet-l) {
      font-size: 16px;
    }
  }
}
