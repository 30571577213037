@import-normalize ; /* bring in normalize.css styles */

html, body {
    margin: 0;
    padding: 0;
    font-family: "Helvetica Neue",
    Arial,
    "Hiragino Kaku Gothic ProN",
    "Hiragino Sans",
    Meiryo,
    sans-serif;
}
