@import '../../styles/main';

.container {
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  justify-content: space-between;

  & .text {
    margin-top: $emoticon-page-text-margin-top;
    white-space: pre-wrap;

    @include mq(tablet-s) {
      margin-top: calc(#{$emoticon-page-text-margin-top} * 1.5);
    }
  }

  & .icon {
    display: block;
    width: $emoticon-icon-size;
    height: auto;
    align-self: center;
    margin: 0 auto;

    @include mq(tablet-s) {
      width: calc(#{$emoticon-icon-size} * 1.5);
    }
  }

  & .button {
    width: fit-content;
    align-self: center;
  }
}
