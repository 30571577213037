@import '../../styles/main';

.container {
  & .fakeHeader {
    height: $fake-header-height;

    @include mq(tablet-s) {
      height: calc(#{$fake-header-height} * 1.5);
    }
  }

  & .text {
    margin-top: $emoticon-page-text-margin-top;

    @include mq(tablet-s) {
      margin-top: calc(#{$emoticon-page-text-margin-top} * 1.5);
    }
  }

  & .icon {
    display: block;
    width: $emoticon-icon-size;
    height: auto;
    align-self: center;
    margin: 0 auto;

    @include mq(tablet-s) {
      width: calc(#{$emoticon-icon-size} * 1.5);
    }
  }

  & .button {
    width: fit-content;
    align-self: center;
  }
}
