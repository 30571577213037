@import 'src/styles/main';

.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;

  & .video {
    display: flex;
    width: 100vw;
    height: 100vh;
    margin: auto;

    &:after {
      position: relative;
    }
  }
}

.qrcode {
  color: black;
}
