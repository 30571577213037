.container {
  position: relative;
  display: flex;
  max-width: 100%;
  max-height: 100%;

  .line {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 0.1px;

    &.left {
      left: 20%;
    }

    &.right {
      right: 20%;
    }
  }

  .img {
    position: relative;
    max-width: 100%;
    max-height: 100%;

    &:after {
      position: absolute;
    }
  }
}
