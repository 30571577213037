@import '../../styles/main';
$horizontal-margin: 24px;
$tablet-s-font-size: 28px;
$tablet-m-font-size: 32px;

.container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;

  & .wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-around;

    & .title {
      margin: 0 $horizontal-margin;
      font-size: 20px;
      font-style: normal;
      font-weight: bold;
      line-height: 140%;
      white-space: pre-wrap;

      @include mq(tablet-s) {
        margin: 0 $space-tablet-s;
        font-size: $tablet-s-font-size;
      }

      @include mq(tablet-m) {
        font-size: $tablet-m-font-size;
      }
    }

    & .date {
      font-size: 22px;
      font-weight: 600;

      @include mq(tablet-s) {
        font-size: $tablet-s-font-size;
      }

      @include mq(tablet-s) {
        font-size: $tablet-m-font-size;
      }
    }

    & .input {
      margin: 0 $horizontal-margin;

      @include mq(tablet-s) {
        margin: 0 $space-tablet-s;
      }
    }
  }

  & .buttonWrapper {
    display: grid;
    grid-row-gap: 20%;

    & .button {
      width: 208px;
      height: 64px;
      margin: 0 auto;

      @include mq(tablet-s) {
        width: 32vw;
        height: auto;
      }
    }
  }
}
