@import 'src/styles/main';

.container {
  display: flex;
  width: 100vw;
  flex-direction: column;
  justify-content: space-between;

  & .fakeHeader {
    height: $fake-header-height;

    @include mq(tablet-s) {
      height: calc(#{$fake-header-height} * 1.5);
    }
  }

  & .wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-around;

    & .alertWrapper {
      display: flex;
      width: fit-content;
      align-self: center;
      padding: 4px 16px;
      border: 1px solid $error-color;
      border-radius: 10px;

      @include mq(tablet-s) {
        padding: 8px 24px;
        border: 1.5px solid $error-color;
        border-radius: 16px;
      }

      & .alert {
        margin: auto;
        font-size: $font-size-little-big;
        font-weight: bold;

        @include mq(tablet-s) {
          font-size: calc(#{$font-size-little-big} * 1.5);
        }

        @include mq(tablet-m) {
          font-size: calc(#{$font-size-little-big} * 1.7);
        }
      }

      & .icon {
        width: auto;
        height: 16px;
        margin-right: 4px;

        @include mq(tablet-s) {
          height: 24px;
        }

        @include mq(tablet-m) {
          height: 28px;
        }
      }
    }

    & .buttonWrapper {
      width: fit-content;
      align-self: center;
      margin-bottom: 16px;

      & .button {
        width: 100%;
        margin-bottom: 12px;
        font-weight: bold;
      }
      & .text {
        font-size: $font-size-little-big;
        font-weight: bold;

        @include mq(tablet-s) {
          font-size: calc(#{$font-size-little-big} * 1.5);
        }
      }
    }
  }
}
