@import 'src/styles/main';

.btn {
  &.text {
    padding: 6px 8px;
    border: none;
    background: none;

    font-size: $font-size-base;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;

    @include mq(tablet-s) {
      padding: 12px 16px;
      font-size: calc(#{$font-size-base} * 1.5);
    }

    &:active {
      border-radius: 20px;

      @include mq(tablet-s) {
        border-radius: 24px;
      }
    }

    &.white {
      color: $white-color;

      &:active {
        background-color: alpha($white-color);
        color: $white-color-hover;
      }
    }

    &.primary {
      color: $primary-color;

      &:active {
        background-color: rgba($primary-color, 0.02);
        color: $primary-color-hover;
      }
    }

    &.secondary {
      color: $secondary-color;

      &:active {
        background-color: rgba($secondary-color, 0.02);
        color: $secondary-color-hover;
      }
    }

    &.error {
      color: $error-color;

      &:active {
        background-color: rgba($error-color, 0.02);
        color: $error-color-hover;
      }
    }

    &.green {
      color: $one-green-color;

      &:active {
        background-color: rgba($one-green-color, 0.02);
        color: $one-green-color-hover;
      }
    }
  }

  &.bold {
    font-size: $font-size-base;
    font-style: normal;
    font-weight: bold;
    line-height: 24px;

    @include mq(tablet-s) {
      font-size: calc(#{$font-size-base} * 1.5);
    }
  }

  &:not(.text) {
    @include _buttonContained;
    font-size: $font-size-little-big;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;

    @include mq(tablet-s) {
      font-size: calc(#{$font-size-little-big} * 1.5);
    }

    &.primary {
      border: none;
      background-color: $primary-color;
      color: $white-color;

      &:active {
        background-color: $primary-color-hover;
      }

      &:disabled {
        background-color: $primary-color-disabled;
      }
    }

    &.green {
      border: none;
      background-color: $one-green-color;
      color: $primary-color;

      &:active {
        background-color: $one-green-color-hover;
      }

      &:disabled {
        background-color: $one-green-color-disabled;
        color: $primary-color-disabled;
      }
    }

    &.gray {
      border: none;
      background-color: $gray-color;
      color: $white-color;

      &:hover {
        background-color: $gray-color-hover;
      }

      &:disabled {
        background-color: $gray-color-disabled;
      }
    }

    &.white {
      box-sizing: border-box;
      border: 1px solid $primary-color;
      background: linear-gradient(180deg, $white-color 0%, $white-color 100%),
        $white-color;
      color: $primary-color;

      &:active {
        background: linear-gradient(
            180deg,
            $white-color-hover 0%,
            $white-color-hover 100%
          ),
          $white-color-hover;
      }

      &:disabled {
        background: linear-gradient(
            180deg,
            $white-color-disabled 0%,
            $white-color-disabled 100%
          ),
          $white-color-disabled;
      }
    }

    &.blue {
      border: none;
      background-color: $blue-color;
      color: $white-color;

      &:active {
        background-color: $blue-color-hover;
      }

      &:disabled {
        background-color: $blue-color-disabled;
        color: $primary-color-disabled;
      }
    }
  }

  &.contained {
    border-radius: 20px;

    @include mq(tablet-s) {
      border-radius: 24px;
    }
  }

  &.rounded {
    padding: 12px 26px;
    border-radius: 48px;
    font-size: $font-size-little-big;
    font-weight: 500;

    @include mq(tablet-s) {
      padding: 24px 52px;
      border-radius: 120px;
      font-size: calc(#{$font-size-little-big} * 1.5);
    }
  }
}
