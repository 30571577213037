@import '../../../styles/main';

.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & .item {
    margin: 0 24px 16px;

    @include mq(tablet-s) {
      margin: 0 60px 18px;
    }
  }

  & .buttonWrapper {
    display: flex;

    & .button {
      min-width: 210px;
      margin: 0 auto 24px;

      @include mq(tablet-s) {
        min-width: 525px;
      }
    }
  }
}
