@import './_variables.scss';
@import '_breakpoint';

// mixin
@import './_mixin.scss';

html {
  font-size: 16px;
  @include mq(tablet-s) {
    font-size: 28px;
  }
}
