.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;

  & > .img {
    width: 100%;
  }

  & > .caption {
    //
  }
}
