@import '../../../styles/main';

.container {
  display: inline-block;
  padding: 32px;
  background-color: $white-color;
  border-radius: 24px;

  & > .text {
    font-size: 14px;
    white-space: pre;

    @include mq(tablet-s) {
      font-size: 16px;
    }
  }

  & > .indicator {
    margin: auto;
  }
}
