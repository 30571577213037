@import 'src/styles/main';

.root {
  display: flex;
  align-items: center;

  .container {
    margin: 0 20px;
    & .text {
      margin-top: 20px;
      font-size: 20px;
      font-weight: bold;

      @include mq(tablet-s) {
        margin-top: 40px;
        font-size: 32px;
      }
    }

    & .description {
      margin-top: 20px;
      font-size: 15px;
      font-weight: 400;
      line-height: 24px;

      @include mq(tablet-s) {
        margin-top: 40px;
        font-size: 24px;
      }
    }

    & .buttonWrapper {
      display: flex;
      justify-content: center;

      & .button {
        margin-top: 20px;
        cursor: pointer;

        @include mq(tablet-s) {
          margin-top: 40px;
          font-size: 24px;
        }
      }
    }

    & .cancelButtonWrapper {
      display: flex;
      justify-content: center;
      margin-top: 20px;

      & .cancelButton {
        color: $white-color;
        font-size: 16px;
        font-weight: 700;

        @include mq(tablet-s) {
          font-size: 24px;
        }
      }
    }
  }
}
