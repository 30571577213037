@import 'src/styles/main';

.container {
  & .icon {
    display: flex;
    width: 100%;
    height: 60px;
    justify-content: center;

    @include mq(tablet-s) {
      height: 110px;
    }

    @include mq(tablet-m) {
      height: 130px;
    }
  }
  & .title {
    font-size: $font-size-huge;
    font-weight: bold;

    @include mq(tablet-s) {
      margin-bottom: 16px;
      font-size: calc(#{$font-size-huge} * 1.4);
    }

    @include mq(tablet-m) {
      margin-bottom: 20px;
      font-size: calc(#{$font-size-huge} * 1.6);
    }
  }

  & .description {
    font-size: $font-size-little-big;

    @include mq(tablet-s) {
      margin-bottom: 4px;
      font-size: calc(#{$font-size-little-big} * 1.2);
    }

    @include mq(tablet-m) {
      margin-bottom: 16px;
      font-size: calc(#{$font-size-little-big} * 1.4);
    }
  }

  &.newLine {
    white-space: pre-wrap;
  }
}
