@import '../../../styles/main';

.container {
  display: inline-block;
  //width: 70px;
  //height: 70px;
  background-size: cover;
}

.icon {
  display: inline-block;
  //width: 70px;
  //height: 70px;
  background-size: cover;
  &.green {
    @include _svgColor($one-green-color);
  }

  &.primary {
    @include _svgColor($primary-color);
  }

  &.white {
    @include _svgColor($white-color);
  }

  &.secondary {
    @include _svgColor($secondary-color);
  }

  &.error {
    @include _svgColor($error-color);
  }

  &.blue {
    @include _svgColor($blue-color);
  }
}
