.container {
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;

  & .wrapper {
    position: relative;

    display: flex;
    overflow: hidden;
    justify-content: center;

    & .img {
      position: absolute;
      width: 100vw;
      max-width: initial;
      height: 100%;

      object-fit: cover;
      object-position: 50% bottom;
      opacity: 0.5;
    }
  }
}
