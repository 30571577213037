@import '../../../styles/main';

.wrapper {
  &.gray {
    background-color: $gray-color;
  }

  &.white {
    background-color: $white-color;
  }

  &.error {
    background-color: $error-color;
  }

  &.primaryTranslucent {
    background-color: rgba($primary-color, 40%);
  }

  &.rounded {
    border-radius: 20px;
  }
}
