@import '../../../styles/main';

$small-size: 44px;
$normal-size: 64px;
$large-size: 92px;

.indicator {
  height: auto;
  padding: 4px;
  &.small {
    width: $small-size;
  }

  &.normal {
    width: $normal-size;
  }

  &.large {
    width: $large-size;
  }
}
