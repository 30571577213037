@import 'src/styles/main';

$tablet-s-scale: 1.6;
$tablet-s-margin-left: 34px;

.root {
  display: flex;

  .check {
    width: 40px;
    min-width: 40px;
    height: 26px;
    margin-right: 16px;

    @include mq(tablet-s) {
      margin-left: $tablet-s-margin-left;
      transform: scale($tablet-s-scale);
    }

    &:after {
      position: relative;
      top: -75%;
      left: 100%;
      display: block;
      width: 16px;
      height: 2px;
      order: -1;
      background-color: $gray-color;
      content: '';
    }
  }

  .step {
    display: flex;
    width: 40px;
    min-width: 40px;
    height: 26px;
    align-items: center;
    justify-content: center;
    background-color: $light-gray-color;
    border-radius: 16px;
    font-size: $font-size-s;

    @include mq(tablet-s) {
      margin-left: $tablet-s-margin-left;
      transform: scale($tablet-s-scale);
    }

    &:not(:last-child) {
      margin-right: 16px;

      &:after {
        position: relative;
        left: 100%;
        width: 16px;
        height: 2px;
        order: -1;
        background-color: #e0e0e0;
        content: '';
      }

      & .number {
        margin-right: 16px;
      }
    }

    &.current {
      background-color: $one-green-color;
    }

    &.finished {
      background-color: $gray-color;
      color: $white-color;
    }
  }
}
