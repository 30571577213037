.container {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  & > .modal {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60%;
    margin: auto;
    transform: translateY(-50%) translateX(-50%);
  }
}

.overlay {
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
