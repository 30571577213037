@import '../../../styles/main';

.container {
  @media (orientation: landscape) {
    height: 100vh;
    padding-bottom: 0;
  }

  & .buttons {
    display: flex;
    justify-content: space-around;
    margin: 0 8px;

    @media (orientation: landscape) {
      flex-direction: column;
    }

    & .buttonWrapper {
      display: flex;
      box-sizing: border-box;
      align-self: center;
      justify-content: center;

      & .buttonWithText {
        display: flex;
        flex-direction: column;
        align-items: center;

        & .button {
          display: flex;
          width: 6vh;
          height: 6vh;
          align-items: center;
          justify-content: center;
          padding: 10%;
          border: none;
          border-radius: 50%;

          @media (orientation: landscape) {
            width: 6vw;
            height: 6vw;
          }
        }

        & .text {
          margin-top: 8%;
          font-size: 0.8rem;
        }
      }

      &.center {
        height: 6vh;
        margin-bottom: auto;

        @media (orientation: landscape) {
          height: 6vw;
          margin-bottom: initial;
        }
      }
    }
  }
}
