@import '../../../styles/main';

.container {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  & > .modal {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60%;
    margin: auto;
    transform: translateY(-50%) translateX(-50%);
  }
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.bg {
  display: inline-block;
  padding: 32px;
  background-color: $white-color;
  border-radius: 24px;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 32px;
    text-align: center;

    .title {
      font-size: 20px;
      font-weight: 700;
    }

    .description {
      margin-top: 12px;
      font-size: 15px;
    }
  }

  & > .buttons {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 44px;

    & > .button {
      display: block;
      flex-grow: 1;

      &.positive {
        margin-bottom: 16px;
      }
    }
  }
}
