@import '../../../styles/main';

.container {
  display: flex;
  justify-content: center;
  border: 1px solid $primary-color;
  border-radius: 12px;

  & .icon {
    width: 18px;
    height: auto;
    align-self: center;
  }

  & .txt {
    align-self: center;
  }
}
