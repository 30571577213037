@import '../../../styles/main';

.container {
  display: flex;
  flex-direction: column;

  & > .tenantName {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;

    @include mq(tablet-s) {
      font-size: 30px;
    }
  }

  & > .shopName {
    font-size: $font-size-base;
    font-style: normal;
    font-weight: normal;
    line-height: 24px;

    @include mq(tablet-s) {
      font-size: calc(#{$font-size-base} * 1.5);
    }
  }
}
