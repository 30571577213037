@import '../../../styles/main';

.list {
  &.white {
    color: $white-color;
  }

  & > .item {
    &::marker {
      @include mq(tablet-s) {
        font-size: calc(#{$font-size-base} * 1.5);
      }
    }
    & .txt {
      margin: 0;
    }
  }
}
