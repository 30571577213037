@import '../../../styles/main';

.container {
  .label {
    display: block;
    margin-bottom: 3px;
    margin-left: 5px;

    @include mq(tablet-s) {
      margin-bottom: 1px;
      font-size: 24px;
    }
  }
  .wrapper {
    position: relative;
    display: inline-block;
    width: 100%;

    &::after {
      position: absolute;
      top: 50%;
      right: 12px;
      color: $primary-color;
      content: var(--suffix);
      font-size: $font-size-little-big;
      font-style: normal;
      font-weight: bold;
      line-height: 22px;
      transform: translateY(-50%);
      transition: all 0.05s ease-in-out;

      @include mq(tablet-s) {
        right: 30px;
        font-size: calc(#{$font-size-little-big} * 1.5);
        line-height: 44px;
      }
    }

    & .form {
      width: 100%;

      &.number {
        padding-right: calc(12px + #{$font-size-little-big});
        text-align: right;

        @include mq(tablet-s) {
          padding-right: calc(30px + #{$font-size-little-big} * 1.5);
        }

        &[type='number']::-webkit-outer-spin-button,
        &[type='number']::-webkit-inner-spin-button {
          margin: 0;
          -webkit-appearance: none;
        }
      }
    }
  }

  & .errorMessage {
    display: flex;
    justify-content: right;
    margin: 8px 12px 0;
  }
}
