@import '../../../styles/main';

.container {
  display: inline-block;
  padding: 32px;
  background-color: $white-color;
  border-radius: 24px;

  & > .message {
    white-space: pre-wrap;
  }

  & > .button {
    display: block;
    margin: auto;
  }
}
