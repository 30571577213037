@import 'src/styles/main';

.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 0;
  margin: 0;

  .header {
    width: 100%;
  }

  .icon {
    width: $emoticon-icon-size;
    height: auto;

    @include mq(tablet-s) {
      width: calc(#{$emoticon-icon-size} * 1.5);
    }
  }

  .title {
    font-family: Arial, 'Hiragino Kaku Gothic ProN', 'Hiragino Sans', Meiryo,
      sans-serif;
  }

  .bottom {
    width: 100%;
    background-color: $light-gray-color;
  }

  .environment {
    width: 100%;

    .note {
      margin: 20px;
    }
  }
}
