@import '../../../styles/main';

.container {
  display: inline-block;
  padding: 32px;
  background-color: $white-color;
  border-radius: 24px;

  & > .buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 44px;

    & > .button {
      display: block;
      flex-grow: 1;

      &.positive {
        margin-bottom: 16px;
      }
    }
  }
}
