@import '../../../styles/main';

.container {
  @media (orientation: landscape) {
    height: 100%;
    align-items: center;
    padding-bottom: 0;
  }

  & .buttons {
    display: flex;
    justify-content: space-around;
    margin: 0 8px;

    @media (orientation: landscape) {
      height: 80%;
      flex-direction: column;
    }

    & .center {
      align-self: center;

      & .centerButton {
        width: $camera-btn-size;
        height: $camera-btn-size;
        margin: 0 auto;

        @include mq(tablet-s) {
          width: calc(#{$camera-btn-size} * 2);
          height: calc(#{$camera-btn-size} * 2);
        }
      }
    }

    & .button {
      display: flex;
      width: 33%;
      height: 52px;
      box-sizing: border-box;
      align-self: center;
      justify-content: center;

      @media (orientation: landscape) {
        width: 100%;
      }

      @include mq(tablet-s) {
        height: 80px;
      }

      & .left {
        border: none;
        text-align: right;

        @media (orientation: landscape) {
          text-align: center;
          white-space: nowrap;
        }
      }

      & .right {
        display: flex;
        align-items: center;
        padding: 12px 20px;
        font-size: 11px;
        line-height: 14px;
        text-align: left;
        white-space: pre-wrap;

        @include mq(tablet-s) {
          padding: 24px 32px;
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
  }
}
