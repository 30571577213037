@import '../../../styles/main';

.mode {
  max-width: 100%;

  &.normal {
    background-color: $white-color;
  }

  &.training {
    background-color: $blue-color02;
    // FIXME: body がなぜか overflow: hidden か margin-top が効いているように見える
    // そのため、上部に意図しない余白ができてしまう。 box-shadow で上側を塗りつぶす
    box-shadow: 0 0 0 100px $blue-color02;
  }

  &.resent {
    background-color: $one-green-color;
    box-shadow: 0 0 0 100px $one-green-color;
  }
}

.modeWrapper {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  margin: 0 10px;
  background-color: $white-color;
  border-radius: 20px 20px 0 0;
}
