@import '../../../styles/main';

.container {
  display: flex;
  flex-direction: column;
  & > .withIcon {
    position: relative;
    & > .icon {
      position: absolute;
      top: 50%;
      width: auto;
      height: 18px;
      margin-left: 20px;
      pointer-events: none;
      transform: translate(-50%, -50%);

      @include mq(tablet-s) {
        height: 45px;
        margin-left: 50px;
      }
    }
  }
  & .error {
    align-self: flex-end;
    margin-top: 4px;
    color: $error-color;
    font-size: 14px;
    text-align: end;

    @include mq(tablet-s) {
      font-size: 35px;
    }
  }
  & .input {
    width: 100%;
  }
}
