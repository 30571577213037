@import 'src/styles/main';

.container {
  position: relative;
  width: 100%;
  height: 100%;

  & .overlay {
    position: absolute;
    z-index: 1;
    left: 0;
    width: 100%;
    height: 100%;
  }

  & .video {
    width: 100%;
    height: 100%;
    margin: auto;

    &:after {
      position: relative;
    }

    @media (orientation: landscape) {
      display: flex;
      width: 85%;
      height: 100%;
      justify-content: center;
      margin: 0;
    }
  }
}
