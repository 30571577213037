@import 'src/styles/main';

.container {
  & .txt {
    padding: 0 24px;
  }
  & .textareaWrapper {
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    margin-top: 60px;
    touch-action: manipulation;
  }
  & .textarea {
    width: 100%;
    height: 160px;
    box-sizing: border-box;
    padding: 8px;
    border-radius: 6px;
    font-size: 16px;
    line-height: 20px;

    @include mq(tablet-s) {
      height: 320px;
      font-size: 30px;
      line-height: 40px;
    }
  }
}
