@import 'src/styles/main';

.container {
  & .txt {
    padding: 0 24px;

    @include mq(tablet-s) {
      padding: 0 48px 60px;
    }
  }
}
