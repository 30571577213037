@import '../../../styles/main';

$tablet-s-font-size: 22px;
$tablet-m-font-size: 24px;
$tablet-s-line-height: 30px;
$tablet-m-line-height: 32px;

.container {
  display: flex;

  & > .homeIcon {
    width: auto;
    height: 16px;
    align-self: center;
    margin-right: 4px;

    @include mq(tablet-s) {
      height: 24px;
    }

    @include mq(tablet-m) {
      height: 28px;
    }
  }

  & > .tenantName {
    align-self: center;
    margin-right: 8px;
    font-size: 13px;
    font-style: normal;
    font-weight: bold;
    line-height: 19px;

    @include mq(tablet-s) {
      font-size: $tablet-s-font-size;
      line-height: $tablet-s-line-height;
    }

    @include mq(tablet-m) {
      font-size: $tablet-m-font-size;
      line-height: $tablet-m-line-height;
    }
  }

  & > .storeName {
    font-size: 13px;
    font-style: normal;
    font-weight: normal;
    line-height: 19px;

    @include mq(tablet-s) {
      font-size: $tablet-s-font-size;
      line-height: $tablet-s-line-height;
    }

    @include mq(tablet-m) {
      font-size: $tablet-m-font-size;
      line-height: $tablet-m-line-height;
    }
  }
}
