@import '../../../styles/main';

.container {
  display: flex;
  flex-direction: column;
  padding: 32px 24px;
  background-color: $white-color;
  border-radius: 24px;

  & > .icon {
    width: 38px;
    height: 33px;

    @include mq(tablet-s) {
      width: 76px;
      height: 66px;
    }
  }
}
