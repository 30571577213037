@import 'src/styles/main';

.root {
  margin-top: 56px;

  .container {
    margin: 0 24px;

    @include mq(tablet-s) {
      margin: 0 32px;
    }

    @include mq(tablet-m) {
      margin: 0 40px;
    }

    .salesDate {
      font-size: 12px;
      font-weight: 400;

      @include mq(tablet-s) {
        font-size: 16px;
      }

      @include mq(tablet-m) {
        font-size: 20px;
        line-height: 30px;
      }
    }

    .createdAt {
      font-size: 13px;
      font-weight: 700;

      @include mq(tablet-s) {
        font-size: 16px;
      }

      @include mq(tablet-m) {
        font-size: 20px;
        line-height: 30px;
      }
    }

    .lastCreatedAt {
      margin-bottom: 12px;
      font-size: 12px;
      font-weight: 400;

      @include mq(tablet-s) {
        font-size: 16px;
      }

      @include mq(tablet-m) {
        font-size: 20px;
        line-height: 30px;
      }
    }

    .notification {
      padding: 16px 0;
      border-bottom: 1px solid $light-gray-color;

      @include mq(tablet-s) {
        padding: 20px 0;
      }

      @include mq(tablet-m) {
        padding: 24px 0;
      }

      @include mq(tablet-l) {
        padding: 28px 0;
      }
    }

    .createAtAndUnreadBadge {
      display: flex;
      align-items: center;
    }

    .unreadBadge {
      margin-left: 8px;
    }

    .isReadByTenant {
      color: $light-gray-color03;
      font-weight: 400;
    }

    .content {
      margin-top: 8px;
      font-size: 15px;
      font-weight: 300;
      line-height: 20px;

      @include mq(tablet-s) {
        margin-top: 16px;
        font-size: 20px;
      }

      @include mq(tablet-m) {
        margin-top: 24px;
        font-size: 24px;
      }

      @include mq(tablet-l) {
        margin-top: 32px;
        font-size: 28px;
      }
    }

    .closeButtonWrapper {
      display: flex;
      justify-content: center;
      padding: 60px 0;

      @include mq(tablet-s) {
        padding: 80px 0;
      }

      @include mq(tablet-m) {
        padding: 100px 0;
      }

      @include mq(tablet-l) {
        padding: 120px 0;
      }
    }
  }
}
