@import 'src/styles/main';

$radius: 32px;

.root {
  display: flex;
  height: 100vh;
  flex-direction: column;
  margin-top: 30px;

  @include mq(tablet-s) {
    margin-top: 60px;
  }

  .container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-around;

    & .txt {
      padding: 0 24px;

      @include mq(tablet-s) {
        padding: 0 $space-tablet-s;
      }
    }

    & .buttonWrapper {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;
      margin: 20% 0;

      @include mq(tablet-s) {
        margin: 10% 0;
      }

      @include mq(tablet-m) {
        margin: 18% 0;
      }

      @include mq(tablet-l) {
        margin: 26% 0;
      }

      & .description {
        margin-bottom: 10px;
        font-size: $font-size-base;

        @include mq(tablet-s) {
          margin-bottom: 14px;
          font-size: calc(#{$font-size-base} * 1.5);
        }
      }

      & .button {
        min-width: 60%;
        font-weight: bold;

        @include mq(tablet-s) {
          min-width: 44%;
          padding: 26px 40px;
        }
      }

      & .alertWrapper {
        display: flex;
        width: fit-content;
        align-self: center;
        padding: 16px 16px;
        border-radius: 10px;

        @include mq(tablet-s) {
          padding: 8px 24px;
          border-radius: 16px;
        }

        & .alert {
          margin: auto;
          font-size: $font-size-m;
          font-weight: 600;

          @include mq(tablet-s) {
            font-size: calc(#{$font-size-m} * 1.8);
          }

          @include mq(tablet-m) {
            font-size: calc(#{$font-size-m} * 2);
          }
        }

        & .icon {
          width: auto;
          height: 16px;
          margin-right: 4px;

          @include mq(tablet-s) {
            height: 24px;
          }

          @include mq(tablet-m) {
            height: 28px;
          }
        }
      }

      & .anchorPCButton {
        width: 220px;
        align-self: center;
        border-bottom: 1px solid $primary-color;
        margin-top: 40px;
        color: $primary-color;
        font-size: 15px;
        font-weight: 700;
        line-height: 24px;

        @include mq(tablet-s) {
          width: 330px;
          margin-top: 60px;
          font-size: 22.5px;
        }
      }

      & .anchorButton {
        width: 152px;
        align-self: center;
        border-bottom: 1px solid $blue-color;
        margin-top: 40px;
        color: $blue-color;
        font-size: 15px;
        font-weight: 700;
        line-height: 24px;

        @include mq(tablet-s) {
          margin-top: 60px;
          font-size: 22.5px;
        }
      }
      & .answerLimit {
        align-self: center;
        margin-top: 12px;
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;

        @include mq(tablet-s) {
          margin-top: 60px;
          font-size: 19.5px;
        }
      }
    }
  }

  & .divider {
    width: 100%;
    height: 1px;
    margin: 24px 0;
    background-color: $light-gray-color02;

    @include mq(tablet-s) {
      margin: 40px 0;
    }

    @include mq(tablet-m) {
      margin: 42px 0;
    }

    @include mq(tablet-l) {
      margin: 48px 0;
    }
  }

  & .bottom {
    display: flex;
    flex-direction: column;
    padding: 0 4% 8%;
    background-color: $primary-color;
    border-radius: $radius $radius 0 0;

    & .bottomHeader {
      margin: 28px 8px;

      & .bottomTitle {
        display: flex;
        margin-bottom: 2%;

        & .title {
          margin: 0;
          font-size: 20px;
          font-weight: 500;

          @include mq(tablet-s) {
            font-size: 28px;
          }

          @include mq(tablet-m) {
            font-size: 32px;
          }
        }
        & .annotation {
          align-self: center;
          margin-left: 10px;
          font-size: 13px;
          font-weight: 400;

          @include mq(tablet-s) {
            font-size: 20px;
          }
        }
      }

      & .description {
        font-size: 13px;
        font-weight: 400;
        white-space: pre-line;

        @include mq(tablet-s) {
          font-size: 20px;
        }
      }
    }

    & .reportHeader {
      display: grid;
      padding: 0 10px 12px;
      grid-template-columns: 1.8fr 3fr;

      @include mq(tablet-s) {
        padding: 0 30px 18px;
        grid-template-columns: 2.2fr 3fr;
      }

      & .headerColumn {
        color: $light-gray-color03;
        font-size: 12px;
        font-weight: 400;

        @include mq(tablet-s) {
          font-size: 18px;
        }
      }
    }

    & .reportWrapper {
      & .report {
        margin-bottom: 2%;
      }
    }
  }
}
