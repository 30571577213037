@import 'src/styles/main';

.container {
  max-width: 100vw;
  height: 100vh;
  background-color: $primary-color;

  @media (orientation: landscape) {
    display: flex;
    align-items: center;
  }

  & .wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;

    & .footer {
      position: absolute;
      z-index: 5;
      bottom: 0;
      left: 0;
      width: 100%;
      padding-bottom: 8%;

      @media (orientation: landscape) {
        bottom: initial;
        left: initial;
        display: flex;
        justify-content: right;
        padding-bottom: 0;
      }
    }
  }
}
