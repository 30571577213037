@import 'src/styles/main';

.container {
  //
  display: flex;
  min-height: 100%;
  flex-direction: column;
  padding: 70px 24px 136px;
  background-color: #000000;

  @include mq(tablet-s) {
    padding: 70px 65px 136px;
  }

  & .text {
    margin-top: 20px;
    font-size: 20px;
    font-weight: bold;

    @include mq(tablet-s) {
      margin-top: 40px;
      font-size: 32px;
    }
  }
}
