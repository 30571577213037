@import 'src/styles/main';

.button {
  // NOTE: Button の Style に勝てないので、important で定義
  padding: 12px 16px !important;
  border: none !important;

  @include mq(tablet-s) {
    padding: 18px 24px !important;
  }

  & .wrapper {
    display: flex;
  }

  & .icon {
    width: 20px;
    height: 20px;
    align-self: center;
    margin-right: 4px;

    @include mq(tablet-s) {
      width: 30px;
      height: 30px;
      margin-right: 8px;
    }
  }

  & .text {
    margin: 0;
    line-height: 20px;
  }
}
