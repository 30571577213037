@import 'src/styles/main';

.container {
  & .txt {
    padding: 0 24px;
  }

  @include mq(tablet-s) {
    & .txt {
      padding: 0 65px;
    }
  }
}
