@import '../../../styles/main';

.checkbox {
  display: flex;
  align-items: center;
  padding: 0;
  border: 0;
  background: transparent;
  color: #9b9b9b;
  cursor: pointer;
  outline: 0;
  text-align: left;

  & > .icon {
    margin-right: 12px;
  }

  & > .text {
    padding-bottom: 2px;
    font-size: $font-size-base;

    @include mq(tablet-s) {
      font-size: calc(#{$font-size-base} * 1.5);
    }
  }
}
