$img-horizontal-margin: 8px;

.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;

  & .title {
    margin-left: 24px;
    white-space: pre-wrap;
  }

  & .imgWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;

    > div {
      text-align: center;
    }
  }

  & .img {
    height: auto;
    margin: 4px $img-horizontal-margin 4px;

    & img {
      width: 100%;
    }
  }

  & .rectangle {
    padding: 8px;
    margin: 4px;
  }

  & .checkboxGroup {
    display: flex;
    flex-direction: column;
    margin: 0 24px;
    touch-action: manipulation;

    & > .checkbox {
      & .txt {
        margin: 0;
      }
    }

    & > .checkbox:not(:first-child) {
      margin-top: 8px;
    }
  }

  & .btn {
    margin: 0 auto;
  }

  .vspacer {
    margin-top: 42px;
  }
}
