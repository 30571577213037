@import 'src/styles/main';

.container {
  display: flex;
  height: 13vw;
  justify-content: center;
  background-color: #f3f3f3;

  .text {
    align-self: center;
    color: $primary-color;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;

    @include mq(tablet-s) {
      font-size: 28px;
    }
  }
}
