@import '../../../styles/main';

.wrapper {
  & .radius {
    border-radius: 32px 32px 0 0;
  }

  & .footer {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
    background-color: $primary-color;

    @include mq(tablet-s) {
      padding-bottom: 48px;
    }

    & .button {
      min-width: 210px;
      margin: 8% auto 0;

      @include mq(tablet-s) {
        min-width: 525px;
      }
    }

    & .description {
      align-self: center;
      margin-top: 12px;
      white-space: pre-wrap;

      @include mq(tablet-s) {
        margin-top: 24px;
      }

      &.hidden {
        visibility: hidden;
      }
    }
  }
}
