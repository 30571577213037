@import 'src/styles/main';

.btn {
  width: 100%;
  padding: unset;
  padding-left: 24px;
  border: unset;
  background-color: unset;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  outline: none;

  @include mq(tablet-s) {
    padding-left: 60px;
    font-size: 30px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  .content {
    display: flex;
    height: 44px;
    align-items: center;
    justify-content: space-between;

    @include mq(tablet-s) {
      height: 88px;
    }
  }

  .icon {
    margin-right: 24px;

    @include mq(tablet-s) {
      width: 22.5px;
      height: 27.5px;
      margin-right: 60px;
    }
  }

  .divider {
    height: 1px;
    background-color: rgba(0, 0, 0, 0.1);
  }
}
