@import 'src/styles/main';

.root {
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  border-bottom: 1px solid $light-gray-color;

  @include mq(tablet-s) {
    padding: 24px 0;
  }

  @include mq(tablet-m) {
    padding: 32px 0;
  }

  .container {
    display: flex;
    flex-direction: column;

    .date {
      font-size: 13px;
      font-weight: 500;
      line-height: 16px;

      @include mq(tablet-s) {
        font-size: 16px;
        font-weight: 550;
      }

      @include mq(tablet-m) {
        font-size: 20px;
        font-weight: 600;
      }
    }

    .reportTime {
      margin-top: 4px;
      color: $light-gray-color03;
      font-size: 10px;
      font-weight: 400;
      line-height: 12px;

      @include mq(tablet-s) {
        margin-top: 8px;
        font-size: 14px;
        line-height: 16px;
      }

      @include mq(tablet-m) {
        margin-top: 12px;
        font-size: 16px;
        line-height: 24px;
      }
    }

    .areAllReadByTenant {
      color: $light-gray-color03;
    }
  }
}

.confirm {
  display: flex;
  align-items: center;

  .unreadBadge {
    margin-right: 8px;
  }

  .confirmText {
    margin-right: 12px;
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;

    @include mq(tablet-s) {
      font-size: 16px;
      font-weight: 750;
    }

    @include mq(tablet-m) {
      font-size: 20px;
      font-weight: 800;
    }
  }
}
