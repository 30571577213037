@import '../../../styles/main';

$inner-position: 3%;

.outer {
  position: relative;
  overflow: hidden;
  box-sizing: border-box;

  border: 3px solid $white-color;
  border-radius: 50%;
  box-shadow: 0 6px 14px rgba(0, 0, 0, 0.2);

  text-indent: 100%;
  white-space: nowrap;

  &.disabled {
    border: 3px solid $white-color-disabled;
  }

  .inner {
    position: absolute;
    top: $inner-position;
    right: $inner-position;
    bottom: $inner-position;
    left: $inner-position;
    width: auto;
    height: auto;

    background: $white-color;
    border-radius: 50%;
    box-shadow: 0 8px 18px rgba(0, 0, 0, 0.2);

    &.disabled {
      background: $white-color-disabled;
    }

    &:hover {
      background: $white-color-hover;
    }
  }

  &:hover {
    border: 3px solid $white-color-hover;
  }
}
