@import '../../styles/main';

.container {
  position: relative;
  width: 100vw;
  height: 100%;
  background-color: black;

  & > .video {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100vw;
    height: 100%;

    &:after {
      position: relative;
    }
  }

  & > .loadingModal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  & > .frameContainer {
    & > .frames {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      width: 100%;
      height: 100%;
      flex-direction: column;
      justify-content: space-between;

      & > .frame {
        flex-grow: 1;
        flex-shrink: 1;

        @include mq(tablet-s) {
          min-height: 20vh;
        }
      }

      & > .space {
        height: 100vw;
      }
    }

    & .footer {
      position: absolute;
      z-index: 10;
      bottom: 0;
      display: flex;
      width: 100%;
      flex-direction: column;
      padding-bottom: 8vw;

      @include mq(tablet-s) {
        padding-bottom: 4vw;
      }

      & > .note {
        margin: 0 auto;

        & > .text {
          font-size: $font-size-base;
          font-style: normal;
          font-weight: normal;
          letter-spacing: -0.4px;
          line-height: 22px;

          @include mq(tablet-s) {
            font-size: calc(#{$font-size-base} * 1.3);
            line-height: 32px;
          }

          @include mq(tablet-m) {
            font-size: calc(#{$font-size-base} * 1.5);
            line-height: 38px;
          }
        }
      }

      & .auth {
        display: flex;
        box-sizing: border-box;
        padding: 12px 20px;
        margin: 2% 2% auto auto;
        font-size: 11px;
        line-height: 14px;
        text-align: left;
        white-space: pre-wrap;

        @include mq(tablet-s) {
          padding: 10px 18px;
          font-size: 14px;
          line-height: 18px;
        }

        @include mq(tablet-m) {
          padding: 14px 22px;
          font-size: 16px;
          line-height: 20px;
        }
      }
    }

    & > .closeIcon {
      position: fixed;
      top: 26px;
      right: 26px;
      width: 5vw;
      height: 5vw;
    }
  }

  & > .modalContainer {
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-content: space-between;
    align-items: center;
    justify-content: space-around;
    background-color: rgba(black, 0.3);

    & > .confirmText {
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
      text-align: center;
      white-space: pre-wrap;

      @include mq(tablet-s) {
        font-size: 40px;
      }
    }

    & > .button {
      min-width: 40vw;

      @include mq(tablet-s) {
        min-width: 32vw;
      }
    }

    & .shopName {
      width: 70%;
      margin: 0 auto;
      opacity: 1;
    }
  }
}
