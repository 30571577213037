@import '../../../styles/main';

.mode {
  max-width: 100%;
  height: 100%;

  &.normal {
    background-color: $white-color;
  }

  &.training {
    background-color: $blue-color02;
  }

  &.resent {
    background-color: $one-green-color;
  }
}

.modeWrapper {
  display: flex;
  height: 100%;
  box-sizing: border-box;
  flex-direction: column;
  margin: 0 10px;
  background-color: $white-color;
  border-radius: 20px 20px 0 0;
}
