.container {
  display: flex;
  align-items: flex-start;
  justify-content: center;

  @media (orientation: landscape) {
    align-items: center;
    justify-content: left;
  }

  .video {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    margin: 0;

    @media (orientation: landscape) {
      height: initial;
    }
  }

  .video::-webkit-media-controls {
    display: none !important;
    opacity: 0;
  }
}
