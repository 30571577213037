@import 'src/styles/main';

.root {
  display: flex;
  align-items: center;

  & .container {
    width: 100%;
    margin: 120px 32px 0px;
  }

  & .title {
    font-size: 20px;
    font-style: normal;
    font-weight: bold;
    line-height: 140%;
    white-space: pre-wrap;

    @include mq(tablet-s) {
      font-size: 28px;
    }

    @include mq(tablet-m) {
      font-size: 32px;
    }
  }

  & .textareaWrapper {
    width: 100%;
    margin-top: 36px;
    touch-action: manipulation;

    & .textarea {
      display: flex;
      width: 100%;
      height: 60px;
      box-sizing: border-box;
      align-items: center;
      padding: 16px 8px;
      border-radius: 20px;
      font-size: 16px;

      @include mq(tablet-s) {
        height: 160px;
        padding: 52px 8px;
        font-size: 30px;
        line-height: 40px;
      }
    }
  }

  & .buttonWrapper {
    display: flex;
    justify-content: center;
    margin-top: 120px;

    & .button {
      width: 150px;

      @include mq(tablet-s) {
        width: 200px;
        font-size: 24px;
      }
    }
  }

  & .cancelButtonWrapper {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    & .cancelButton {
      font-size: 16px;
      font-weight: 700;

      @include mq(tablet-s) {
        font-size: 24px;
      }
    }
  }
}
