@import '../../../styles/main';

$space: 12px;

.container {
  position: relative;
  display: inline-block;
  width: auto;
  height: auto;

  .image {
    display: grid;
  }
  .altText {
    position: absolute;
    bottom: 25px;
    left: 18px;
    font-size: $font-size-little-big;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
  }
  .button {
    position: absolute;
    right: $space;
    bottom: $space;
  }
  .close {
    position: absolute;
    top: $space;
    right: $space;
    width: 25px;
    height: auto;
  }
}
