@import '../../../styles/main';

.root {
  display: flex;
  flex-direction: column;

  & .button {
    position: relative;
    padding: 12px;
    border: 1px solid $light-gray-color02;
    background-color: $white-color;
    border-radius: 20px;
    color: $primary-color;
    font-size: $font-size-little-big;
    line-height: 30px;
    text-align: center;
    @include mq(tablet-s) {
      font-size: calc(#{$font-size-little-big} * 1.5);
      line-height: 44px;
    }
  }

  & .window {
    position: absolute;
    top: 100%;
    left: 50%;
    width: 100%;
    max-height: 202px;
    border: 1px solid $light-gray-color02;
    margin-top: 8px;
    background-color: $white-color;
    border-radius: 20px;
    overflow-y: auto;
    transform: translateX(-50%);
    @include mq(tablet-s) {
      max-height: 372px;
      margin-top: 12px;
    }
    @include mq(tablet-m) {
      max-height: 428px;
      margin-top: 12px;
    }

    & .list {
      padding: 4px 16px;
      @include mq(tablet-s) {
        padding: 8px 24px;
      }
    }

    & .item {
      display: flex;
      height: 44px;
      align-items: center;
      @include mq(tablet-s) {
        height: 56px;
      }
    }

    & .label {
      font-size: $font-size-little-big;
      @include mq(tablet-s) {
        font-size: calc(#{$font-size-little-big} * 1.5);
      }
    }
    & .badge {
      padding: 4px 12px;
      border: 1px solid $light-gray-color03;
      margin-left: 16px;
      border-radius: 8px;
      color: $light-gray-color03;
      font-size: 12px;
      @include mq(tablet-s) {
        font-size: 18px;
      }
    }
  }

  & .iconWrapper {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);

    & .icon {
      width: 12px;
      height: 12px;
      transition: 0.2s ease-in-out;
      @include mq(tablet-s) {
        width: 16px;
        height: 16px;
      }
    }

    & .rotate {
      transform: rotate(180deg);
    }
  }
}
