@import '../../../styles/main';

.wrapper {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  padding: 16px 24px;
  background-color: $primary-color;
  border-radius: 32px 32px 0 0;

  @include mq(tablet-s) {
    padding: 28px 48px 20px;
  }

  & .results {
    display: flex;
    flex-direction: column;
    padding: 0 24px;

    @include mq(tablet-s) {
      padding: 0 48px 30px;
    }

    & .item {
      display: flex;
      justify-content: space-between;
      &:not(:last-child) {
        margin-bottom: 4px;

        @include mq(tablet-s) {
          margin-bottom: 8px;
        }
      }

      & .unit {
        margin-left: 4px;

        @include mq(tablet-s) {
          margin-left: 8px;
        }
      }
    }
  }
}
