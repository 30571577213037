@import '../../../styles/main';

$tablet-s-line-height: 40px;

.typography {
  @include _textColor;

  &.largeTitle {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;

    @include mq(tablet-s) {
      font-size: 36px;
    }
  }

  &.middleTitle {
    font-size: $font-size-little-big;
    line-height: 24px;

    @include mq(tablet-s) {
      font-size: calc(#{$font-size-little-big} * 1.5);
      line-height: $tablet-s-line-height;
    }
  }

  &.smallTitle {
    font-size: 13px;
    font-style: normal;
    font-weight: bold;
    line-height: 19px;

    @include mq(tablet-s) {
      font-size: 26px;
      line-height: 57px;
    }
  }

  &.description {
    font-size: $font-size-base;
    font-style: normal;
    font-weight: normal;
    line-height: 24px;

    @include mq(tablet-s) {
      font-size: calc(#{$font-size-base} * 2);
      line-height: $tablet-s-line-height;
    }
  }

  &.caption {
    font-size: $font-size-base;
    font-style: normal;
    font-weight: bold;
    line-height: 24px;

    @include mq(tablet-s) {
      font-size: calc(#{$font-size-base} * 1.5);
      line-height: $tablet-s-line-height;
    }
  }

  &.note {
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;

    @include mq(tablet-s) {
      font-size: 24px;
    }
  }

  &.center {
    text-align: center;
  }

  &.left {
    text-align: left;
  }

  &.bold {
    font-weight: bold;
  }

  &.newline {
    white-space: pre-wrap;
  }

  &.blue {
    color: $blue-color;
  }
}
