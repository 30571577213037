@import 'src/styles/main';

.root {
  margin-top: 56px;

  .titleWrapper {
    margin: 0 20px;

    @include mq(tablet-s) {
      margin: 0 32px;
    }

    @include mq(tablet-m) {
      margin: 0 40px;
    }

    .description {
      font-size: 12px;
      font-weight: 400;
      white-space: pre;

      @include mq(tablet-s) {
        font-size: 15px;
      }

      @include mq(tablet-m) {
        font-size: 18px;
        line-height: 30px;
      }
    }
  }

  .tenantNotificationBySalesDate {
    margin: 0 32px;

    @include mq(tablet-s) {
      margin: 0 40px;
    }

    @include mq(tablet-m) {
      margin: 0 48px;
    }
  }
}
