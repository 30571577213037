@import 'src/styles/main';

.container {
  display: flex;
  justify-content: center;
  margin: auto;

  @media (orientation: landscape) {
    justify-content: left;
  }

  & .overlay {
    position: absolute;
    bottom: 0;
    width: 60%;
    height: 90%;
    border-radius: 20px 20px 0 0;
    box-shadow: $overlay-shadow;

    @media (orientation: landscape) {
      display: none;
    }

    &.full {
      height: 100%;
      border-right: 4px solid $guide-line-color;
      border-left: 4px solid $guide-line-color;
      border-radius: 0;
    }

    & .border {
      position: absolute;
      width: 50px;
      height: 50px;
      border: 4px solid transparent;
      border-radius: 40%;

      &.left {
        top: -1px;
        left: -1px;
        border-top-color: $guide-line-color;
        border-left-color: $guide-line-color;
        border-bottom-left-radius: 10%;
        border-top-right-radius: 10%;
      }
      &.right {
        top: -1px;
        right: -1px;
        border-top-color: $guide-line-color;
        border-right-color: $guide-line-color;
        border-bottom-right-radius: 10%;
        border-top-left-radius: 10%;
      }

      @media (orientation: landscape) {
        display: none;
      }
    }
  }

  & .img {
    overflow: hidden;
    max-width: 100vw;
    max-height: 100vh;

    @media (orientation: landscape) {
      width: 85%;
    }
  }
}
