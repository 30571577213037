@import '../../styles/main';

.container {
  display: grid;
  height: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  padding-top: 4%;
  grid-template-rows: auto 1fr auto;

  & .stepper {
    margin: 0 0 6%;
  }

  & .title {
    margin-left: 24px;
    font-weight: bold;
    white-space: pre-wrap;

    @include mq(tablet-s) {
      margin-left: 60px;
    }
  }

  & .forms {
    margin-top: 20px;

    @include mq(tablet-s) {
      margin-top: 48px;
    }
  }

  & .results {
    position: sticky;
    position: -webkit-sticky; // Add prefix for Safari on iOS < 13
    bottom: 0;
  }

  & .footer {
    position: relative;
    // FIXME: 下部に謎の余白が生まれてしまう。そのため、box-shadow で塗り潰している。
    box-shadow: 0px 5px 0px 0px;
  }
}
