@import '../../../styles/main';
input[type='date'] {
  -webkit-appearance: none;
}
input[type='date']::-webkit-date-and-time-value {
  margin: 0 auto;
  text-align: center;
}
input[type='date']::-webkit-input-placeholder {
  visibility: hidden !important;
}
input[type='date']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type='date']::-webkit-clear-button {
  -webkit-appearance: none;
}
input[type='date']::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
  opacity: 0;
}

.input {
  @include _inputForm;
  text-align: center;
}
