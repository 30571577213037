@import '../../../styles/main';

$tablet-s-font-size: 20px;
$tablet-m-font-size: 22px;

.mode_header {
  display: flex;
  justify-content: space-between;
  margin: 12px 20px;
  color: $white-color;
  font-size: $font-size-m;

  &.normal {
    color: $primary-color;
  }

  .icon {
    width: 20px;
    height: 20px;
  }

  p {
    display: flex;
    align-items: center;
    margin: 0;
    font-size: 12px;
    font-weight: bold;

    &.title {
      margin-right: 10px;

      @include mq(tablet-s) {
        font-size: $tablet-s-font-size;
      }

      @include mq(tablet-m) {
        font-size: $tablet-m-font-size;
      }
    }

    &.date {
      font-weight: bold;

      @include mq(tablet-s) {
        font-size: $tablet-s-font-size;
      }

      @include mq(tablet-m) {
        font-size: $tablet-m-font-size;
      }
    }

    &.mode {
      padding: 4px 12px;
      border-radius: 20px;
      font-weight: bold;

      @include mq(tablet-s) {
        padding: 8px 16px;
        font-size: $tablet-s-font-size;
      }

      @include mq(tablet-m) {
        padding: 10px 20px;
        font-size: $tablet-m-font-size;
      }

      &.normal {
        background-color: $light-gray-color;
      }

      &.training {
        border: 1px solid $white-color;
        background-color: #0e22d8;
      }

      &.resent {
        background-color: $reported-color;
      }
    }
  }

  .title_wrapper {
    display: flex;
    align-items: center;
  }
}
