@import '../../../styles/main';

$font-size-tablet-s: 20px;
$font-size-tablet-m: 22px;

p {
  margin: 0px;
  font-size: 12px;
  line-height: 17px;

  @include mq(tablet-s) {
    font-size: 16px;
  }
}

.root {
  display: grid;
  height: 40px;
  align-items: center;
  padding: 10px;
  border-left: 4px solid $sales-record-base-color;
  background-color: $sales-record-base-color;
  font-size: 14px;
  font-weight: 700;
  grid-template-columns: 1.8fr 3fr;

  @include mq(tablet-s) {
    height: 60px;
    padding: 18px;
    border-left-width: 6px;
    font-size: $font-size-tablet-s;
    grid-template-columns: 2.2fr 3fr;
  }

  @include mq(tablet-m) {
    font-size: $font-size-tablet-m;
  }

  &.waiting {
    border-left-color: $waiting-color;
  }
  &.reported,
  &.imageResent {
    border-left-color: $one-green-color;
  }
  &.finalizedReportSubmitted,
  &.finalizedReportSynced {
    border-left-color: $light-gray-color03;
    background-color: transparent;
  }
  & .dateWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: center;
    & .date {
      margin-bottom: 2px;
      color: $white-color;
    }
    & .reportTime {
      display: flex;
      flex-direction: column;
      color: $light-gray-color03;
      font-size: 10px;
      font-weight: 400;
      @include mq(tablet-s) {
        font-size: 16px;
      }
    }
  }

  & .statusWrapper {
    display: grid;
    grid-template-columns: 2fr 2.5fr;

    & .status {
      display: flex;
      align-items: center;
      font-size: 12px;
      text-align: left;
      white-space: nowrap;

      @include mq(tablet-s) {
        font-size: $font-size-tablet-s;
      }

      @include mq(tablet-m) {
        font-size: $font-size-tablet-m;
      }

      &.waiting {
        color: $waiting-color;
        font-weight: 700;
      }
      &.reported,
      &.imageResent {
        color: $one-green-color;
        font-weight: 700;
      }
      &.finalizedReportSubmitted,
      &.finalizedReportSynced {
        color: $light-gray-color03;
        font-weight: 400;
      }

      & .statusIcon {
        width: 8px;
        height: 8px;
        margin-right: 4px;

        @include mq(tablet-s) {
          width: 12px;
          height: 12px;
          margin-right: 6px;
        }

        @include mq(tablet-m) {
          width: 14px;
          height: 14px;
        }
      }
    }

    & .clickable {
      display: flex;
      font-size: 12px;

      @include mq(tablet-s) {
        font-size: $font-size-tablet-s;
      }

      @include mq(tablet-m) {
        font-size: $font-size-tablet-m;
      }

      & .button {
        display: flex;
        align-items: center;
        margin: 0 auto;
        white-space: pre;

        &.waiting {
          background-color: $waiting-badge-color;
          color: $waiting-color;
        }
        &.reported,
        &.imageResent {
          background-color: $reported-badge-color;
          color: $reported-color;
        }
        &.finalizedReportSubmitted,
        &.finalizedReportSynced {
          color: $light-gray-color03;
          font-weight: 400;
        }
        &.waiting,
        &.reported,
        &.imageResent {
          padding: 6px 7px;
          border-radius: 30px;

          @include mq(tablet-s) {
            padding: 12px;
          }
        }

        & span {
          display: flex;
          align-items: center;
          line-height: 1;
        }

        & .icon {
          width: 8px;
          height: 8px;
          margin-left: 2px;
          line-height: 1;

          @include mq(tablet-s) {
            width: 14px;
            height: 14px;
            margin-left: 4px;
          }

          @include mq(tablet-m) {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }
}
