// color
$primary-color: #000000;
$primary-color-hover: lighten($primary-color, 20%);
$primary-color-disabled: lighten($primary-color, 70%);
$secondary-color: #d90000;
$secondary-color-hover: lighten($secondary-color, 20%);
$white-color: #ffffff;
$white-color-hover: darken($white-color, 20%);
$white-color-disabled: darken($white-color, 30%);
$gray-color: #333333;
$gray-color-hover: darken($gray-color, 20%);
$gray-color-disabled: darken($gray-color, 30%);
$light-gray-color: #f3f3f3;
$light-gray-color02: #e1e1e1;
$light-gray-color03: #939393;
$disabled-color: #d0d9d3;
$one-green-color: #3ee3a6;
$one-green-color-hover: lighten($one-green-color, 20%);
$one-green-color-disabled: lighten($one-green-color, 30%);
$error-color: #ff0c3e;
$error-color-hover: lighten($error-color, 20%);
$error-color-disabled: lighten($error-color, 70%);
$blue-color: #121f98;
$blue-color02: #4255ff;
$blue-color-hover: lighten($blue-color, 20%);
$blue-color-disabled: lighten($blue-color, 70%);
$sales-record-base-color: #1a1a1a;
$reported-color: #07a56c;
$waiting-color: #ff5757;
$reported-badge-color: #d4ffee;
$waiting-badge-color: #ffeded;

// font-size
$font-size-s: 11px;
$font-size-m: 13px;
$font-size-base: 15px;
$font-size-little-big: 16px;
$font-size-big: 18px;
$font-size-huge: 20px;

// boarder
$frame-border-size: 6px;
$frame-border-radius: 12px;

// emoticon page
$emoticon-icon-size: 60px;
$fake-header-height: 71px;
$emoticon-page-text-margin-top: 46px;

// camera
$camera-btn-size: 68px;
$overlay-shadow: 0 0 0 10000px rgba(0, 0, 0, 0.6);
$overlay-height: 95%;
$guide-line-color: #41edad;

// padding and margin
$space-tablet-s: 65px;
