@import 'src/styles/main';

$radius: 32px;

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 42px;
  padding-left: 42px;
  background-color: $primary-color;
  border-radius: $radius $radius 0 0;

  @include mq(tablet-s) {
    padding: 0 105px;
  }

  .icon {
    width: 38px;
    height: 50px;

    @include mq(tablet-s) {
      height: 60px;
      margin-bottom: 20px;
    }
  }

  .title {
    color: $white-color;
    font-size: 22px;
    font-style: normal;
    font-weight: bold;
    line-height: 26px;

    @include mq(tablet-s) {
      font-size: 40px;
    }
  }

  .version {
    margin-bottom: 16px;
    font-size: $font-size-little-big;

    @include mq(tablet-s) {
      font-size: calc(#{$font-size-little-big} * 1.5);
    }
  }
}
