@import './_variables.scss';
@mixin _textColor {
  &.white {
    color: $white-color;
  }
  &.primary {
    color: $primary-color;
  }
  &.secondary {
    color: $secondary-color;
  }
  &.error {
    color: $error-color;
  }
  &.green {
    color: $one-green-color;
  }
}

@mixin _borderColor {
  &.white {
    border-color: $white-color;
  }
  &.primary {
    border-color: $primary-color;
  }
  &.secondary {
    border-color: $secondary-color;
  }
  &.error {
    border-color: $error-color;
  }
  &.green {
    border-color: $one-green-color;
  }
}

@mixin _bgColor {
  &.white {
    background-color: $white-color;
  }
  &.primary {
    background-color: $primary-color;
  }
  &.secondary {
    background-color: $secondary-color;
  }
  &.error {
    background-color: $error-color;
  }
  &.green {
    background-color: $one-green-color;
  }
}

@mixin _buttonContained {
  padding: 20px 30px;
  border-radius: 20px;
  font-weight: 600;
  line-height: 1.5rem;

  @include mq(tablet-s) {
    padding: 40px 30px;
    border-radius: 40px;
  }
}

@mixin _formInput {
  box-sizing: border-box;
  padding: 20px;
  border: 1px solid $light-gray-color02;
  border-radius: 20px;
  font-size: $font-size-little-big;

  font-style: normal;
  font-weight: 600;
  line-height: 22px;

  text-align: center;
}

@mixin _svgColor($color) {
  :global(.fill) {
    fill: $color;
  }
  :global(.fill-stroke) {
    stroke: $color;
  }
}

@mixin mq($breakpoint) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

@mixin _inputForm() {
  box-sizing: border-box;
  padding: 14px;
  border: 1px solid $light-gray-color02;
  appearance: none;

  background-clip: padding-box;
  background-color: $white-color;
  border-radius: 20px;
  -webkit-focus-ring-color: rgba(255, 255, 255, 0) !important;
  font-size: $font-size-little-big;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  outline: none !important;

  -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;

  @include mq(tablet-s) {
    padding: 12px;
    font-size: calc(#{$font-size-little-big} * 1.5);
    line-height: 44px;
  }
}
