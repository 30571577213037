@import '../../../styles/main';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  white-space: pre-wrap;

  .title {
    display: block;
    margin: 0 24px;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;

    @include mq(tablet-s) {
      font-size: 55px;
      line-height: 65px;
    }
  }

  .subtitle {
    display: block;
    margin-top: 14px;
    font-size: $font-size-little-big;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;

    @include mq(tablet-s) {
      font-size: calc(#{$font-size-little-big} * 2);
      line-height: 40px;
    }
  }

  @include _textColor;
}
