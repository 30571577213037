@import '../../../styles/main';
$close-btn-size: 8vw;
$close-btn-position: 5vh;

.container {
  height: 100%;
  background: $primary-color;

  & .header {
    & .closeIcon {
      position: fixed;
      z-index: 100;
      top: $close-btn-position;
      right: $close-btn-position;
      width: $close-btn-size;
      height: $close-btn-size;

      @media (orientation: landscape) {
        right: auto;
        left: $close-btn-position;
        width: calc(#{$close-btn-size} / 1.5);
      }
    }
  }

  & .fakeImg {
    position: absolute;
    z-index: 10;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: auto;
  }

  & .bottom {
    position: absolute;
    z-index: 10;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-bottom: 8%;

    @media (orientation: landscape) {
      height: 100vh;
      padding-bottom: 0;
    }

    & .buttons {
      @media (orientation: landscape) {
        left: initial;
        display: flex;
        height: 100%;
        justify-content: right;
        padding-bottom: 0;
      }
    }

    & .hr {
      width: 70%;
      height: 20px;
      border: none;
      border-top: 3px dashed $guide-line-color;
      margin: 0 auto;

      @include mq(tablet-s) {
        height: 40px;
      }

      @media (orientation: landscape) {
        display: none;
      }
    }
  }

  & .noteWrapper {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  & .note {
    display: flex;
    height: 25px;
    align-items: center;
    justify-content: center;
    padding: 0px 10px;
    border-radius: 10px;

    @media (orientation: landscape) {
      display: none;
    }

    &.first {
      position: absolute;
      z-index: 10;
      top: 2%;
    }

    &.second {
      margin-bottom: 20px;

      @include mq(tablet-s) {
        margin-bottom: 40px;
      }
    }

    &.third {
      position: absolute;
      z-index: 10;
      top: 45%;
      right: 0;
      left: 0;
    }
  }

  & .canvas {
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    width: 100vw;
    height: 100vh;
    visibility: hidden;
  }
}
