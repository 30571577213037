@import '../../../styles/main';

// px指定をどうにかしたい

$circle-size: 30px;
$list-style-margin: 27px;
$tablet-s-font-size: 24px;

@mixin circleStyleColor($text-color, $circle-text-color) {
  & > .item {
    color: $text-color;
    &:before {
      background: $text-color;
      color: $circle-text-color;
    }
  }
}

.list {
  padding: 0;
  counter-reset: number;
  list-style-type: none;

  &.white {
    @include circleStyleColor($white-color, $primary-color);
  }

  & > .item {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    align-self: center;
    font-size: $font-size-little-big;
    line-height: 24px;
    //color: $white-color;

    @include mq(tablet-s) {
      font-size: $tablet-s-font-size;
      line-height: 30px;
    }

    &:before {
      left: 0;
      // bg
      display: block;
      width: $circle-size;
      height: $circle-size;
      flex-shrink: 0;
      margin-right: $list-style-margin;
      //background: $white-color;
      border-radius: 50%;
      // 数字
      content: counter(number);
      counter-increment: number;
      font-size: $font-size-little-big;
      //color: $primary-color;
      font-weight: bold;
      line-height: 30px;
      text-align: center;

      @include mq(tablet-s) {
        width: calc(#{$circle-size} * 1.5);
        height: calc(#{$circle-size} * 1.5);
        font-size: $tablet-s-font-size;
        line-height: 44px;
      }
    }
  }
}
